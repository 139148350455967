[role="dialog"] {
  z-index: 1550 !important;
}
#portal-root {
  position: relative;
  z-index: 9999 !important;
}

#modal-content{
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0,0,0,0.5);

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}